/* Personalized fonts file */
@font-face {
    font-family: "Source Code Pro";
    src: url("/static/fonts/SourceCodePro-VariableFont_wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Code Pro";
    src: url("/static/fonts/SourceCodePro-Italic-VariableFont_wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("/static/fonts/SourceSans3-VariableFont_wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("/static/fonts/SourceSans3-Italic-VariableFont_wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Source Serif Pro";
    src: url("/static/fonts/SourceSerif4-VariableFont_opsz,wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Serif Pro";
    src: url("/static/fonts/SourceSerif4-Italic-VariableFont_opsz,wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Geist";
    src: url("/static/fonts/Geist[wght].ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Geist Mono";
    src: url("/static/fonts/GeistMono[wght].ttf") format("truetype");
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
}

/* TailwindCSS */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Components */
.input {
    @apply bg-white border border-gray-300 rounded-lg block w-full px-4 py-2;
}
.input-gold {
    @apply bg-white border border-gold shadow-md rounded-lg block w-full px-4 py-2;
}
.dark .input {
    @apply bg-bgDark-700 border border-borderDark rounded-lg block w-full px-4 py-2 text-white;
}
.dark .input-gold {
    @apply bg-bgDark-700 border border-gold rounded-lg block w-full px-4 py-2 text-white;
}
.input[readOnly] {
    @apply bg-gray-100 dark:bg-bgDark-550;
}
.label {
    @apply text-gray-700 mb-1 block font-bold tracking-tight;
}
.dark .label {
    @apply text-white mb-1 block font-bold tracking-tight;
}
.button {
    @apply shadow-md transition duration-300 relative block;
}
.sidenav-link {
    @apply hover:bg-[#05060F] dark:hover:bg-lightBlue dark:hover:bg-opacity-10 hover:bg-opacity-5 duration-100 text-[#05060F] dark:text-lightBlue text-opacity-60 font-geistSans md:text-sm rounded-xl px-2 py-2.5 cursor-pointer flex items-center gap-3;
}
html {
    --scroll-behavior: smooth !important;
    scroll-behavior: smooth !important;
}
pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}
@layer utilities {
    .background-not-blur {
        background-color: transparent;
        backdrop-filter: none;
        box-shadow: none;
    }
    .background-blur {
        backdrop-filter: blur(12px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    .background-blur-white {
        background-color: rgba(255, 255, 255, 0.74);
        backdrop-filter: blur(15px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    .background-blur-dark {
        background-color: rgba(61, 62, 80, 0.57);
        backdrop-filter: blur(12px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    .background-blur-primary {
        background-color: rgba(232, 81, 28, 0.17);
        backdrop-filter: blur(12px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    .background-blur-aside {
        background-color: rgba(255, 255, 255, 0.17);
        backdrop-filter: blur(12px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    .effect-scribble {
        position: relative;
        z-index: 10;
    }
    .effect-scribble::before {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 40px;
        width: 120%;
        left: -10%;
        bottom: -16px;
        z-index: -1;
        background-image: url("/images/site/assets/scribble.svg");
    }
    .font-title {
        line-height: "4rem";
        letter-spacing: "-1.4px";
        @apply font-sourceSans font-semibold;
    }
    .experimental-background {
        background: linear-gradient(180deg, #f5f0fe, #eef5fc);
    }
    .after-line {
        @apply uppercase md:text-xs text-sm font-bold text-[#05060F] dark:text-white text-opacity-60 tracking-wider flex relative my-4;
    }
    .after-line div {
        @apply bg-[#F9F9F9] dark:bg-[#252837] z-10 pr-1.5;
    }
    .after-line::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 10px;
        width: calc(100% - 10px);
        min-width: 30px;
        height: 1px;
        @apply bg-[#05060F] dark:bg-[#54578c] bg-opacity-5;
    }
}
/* Assurez-vous que le conteneur soit en position relative pour positionner les pseudo-éléments */
.gradient-wrapper {
    position: relative;
}

/* Pseudo-élément pour le dégradé gauche */
.gradient-wrapper::before,
.gradient-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 10;
}

/* Dégradé à gauche : du blanc (ou couleur de fond) vers transparent */
.gradient-wrapper::before {
    left: 0;
    width: 20px;
    background: linear-gradient(to right, white, transparent);
}

/* Dégradé à droite : du blanc (ou couleur de fond) vers transparent */
.gradient-wrapper::after {
    right: 0;
    width: 20px;
    background: linear-gradient(to left, white, transparent);
}

/* Masquer le dégradé gauche lorsque l'utilisateur est au tout début */
.gradient-wrapper.hide-left::before {
    display: none;
}

/* Masquer le dégradé droit lorsque l'utilisateur est à la fin */
.gradient-wrapper.hide-right::after {
    display: none;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}
.heartbeat {
    animation: heartbeat 1s infinite;
}
@keyframes colorChange {
    0% {
        background-color: rgb(229 21 120 / 40%);
    }
    50% {
        background-color: rgb(232 81 28 / 10%);
    }
    100% {
        background-color: rgb(229 21 120 / 40%);
    }
}

.bg-animate {
    animation: colorChange 2s infinite ease-in-out;
}

figure.align-left {
    float: left;
}

figure.align-right {
    float: right;
}

figure.image figcaption {
    margin: 6px 8px 15px 8px;
    text-align: center;
    font-size: 0.8rem;
}
.button:active {
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
}
a,
.fake-link {
    @apply text-primary;
}
a:hover,
.fake-link:hover {
    @apply text-primary-300;
}
p {
    @apply mb-3;
}
.discussion-messenger a {
    color: white !important;
    text-decoration: underline !important;
}
.dropdown-item {
    @apply px-4 py-2 hover:bg-primary transition duration-150 cursor-pointer;
}

footer a,
footer .fake-link {
    @apply text-gray-700 dark:text-lightBlue hover:text-primary dark:hover:text-primary;
}

.mce-content-body {
    border-color: rgb(209 213 219) !important;
    @apply bg-white;
}
.dark .mce-content-body {
    border-color: rgb(56 68 77) !important;
    @apply bg-bgDark-700 text-white;
}
.tox-toolbar__group:not(:last-of-type) {
    border-color: rgb(209 213 219) !important;
}
.dark .tox-toolbar__group:not(:last-of-type) {
    border-color: rgb(56 68 77) !important;
}
.tox .tox-toolbar-overlord,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary,
.tox-tinymce-inline .tox-editor-header {
    background-color: transparent !important;
    border: 0 !important;
}
.tox .tox-collection__item-label * {
    background-color: transparent !important;
}

.tox .tox-tbtn svg {
    @apply text-black dark:text-white;
}

.line-loading {
    height: 1.5px;
    overflow: hidden;
}
.custom-details summary {
    position: relative;
    display: block;
    padding: 0.5rem;
}
.custom-details summary:before {
    color: #d4dcff;
    content: "▶";
    font-size: 10px;
    padding: 1px;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    position: absolute;
}
.custom-details[open] summary:before {
    content: "▼";
}
.line-loading:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 1.5px;
    width: 40%;
    @apply bg-primary-300;
    -webkit-animation: lineAnim 1.5s linear infinite;
    -moz-animation: lineAnim 1.5s linear infinite;
    animation: lineAnim 1.5s linear infinite;
}

.gradient-pop {
    background: -webkit-linear-gradient(-70deg, #9867f0 0%, #ed4e50 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

.gradient-mint-blue {
    background: linear-gradient(271.72deg, #a2facf 7.09%, #64acff 96.61%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.filter-gray {
    filter: invert(1) grayscale(100%) contrast(0.5);
    transition: filter 0.5s ease-in-out;
}

.premium-only [data-premium-only="true"] {
    filter: blur(10px);
    transition: all 0.1s ease-in-out;
    color: transparent !important;
    text-shadow: 0 0 17px hsl(0deg 0% 100% / 75%);
}
@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 50%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}

@keyframes scroll {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

.animate {
    animation: scroll 30s linear infinite;
}

/* Style */
.welcome-bg {
    background-image: url("/images/site/assets/circle.svg");
    background-size: 65%;
    background-position: 130% top;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    right: 0;
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
}
.sidebar {
    position: fixed;
    top: 0;
    color: white;
    bottom: 0;
    right: -251px;
    width: 251px;
    z-index: 1000;
    transition: all 0.5s;
    overflow-y: auto;
    padding: 19px;
    @apply bg-white dark:bg-bgDark-550;
}
.sidebar ul {
    list-style-type: none;
    margin: 30px 0 0 0;
    padding: 0;
}
.sidebar li {
    display: block;
    margin: 0;
    font-weight: 500;
    padding: 1rem 1rem;
    border-radius: 10px;
    margin-bottom: 0.8rem;
    transition: all 0.15s;
    @apply text-black dark:text-white;
}
.sidebar li:hover {
    color: white;
    background: #222228;
}
.sidebar li.active {
    background: "#613BDB";
    color: white;
}
.shiki {
    @apply md:px-4 px-2.5 md:py-3 py-2.5;
    font-size: 1rem;
    line-height: 1.5rem;
}
.dark .shiki,
.dark .shiki span {
    color: var(--shiki-dark) !important;
    background-color: var(--shiki-dark-bg) !important;
    /* Optional, if you also want font styles */
    font-style: var(--shiki-dark-font-style) !important;
    font-weight: var(--shiki-dark-font-weight) !important;
    text-decoration: var(--shiki-dark-text-decoration) !important;
}
/* code[class*="language-"],
pre[class*="language-"] {
    color: #f8f8f2;
    background: 0 0;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #272822;
}
:not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
} */
/* .token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #8292a2;
}
.token.punctuation {
    color: #f8f8f2;
}
.token.namespace {
    opacity: 0.7;
}
.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
    color: #f92672;
}
.token.boolean,
.token.number {
    color: #ae81ff;
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #a6e22e;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #f8f8f2;
}
.token.atrule,
.token.attr-value,
.token.class-name,
.token.function {
    color: #e6db74;
}
.token.keyword {
    color: #66d9ef;
}
.token.important,
.token.regex {
    color: #fd971f;
}
.token.bold,
.token.important {
    font-weight: 700;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
} */
.sidebar-active {
    right: 0px !important;
}
.backdrop {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    animation: fade 0.1s;
    z-index: 50;
}
.backdrop-active {
    display: block;
}
.copy-to-clipboard-button {
    background-color: #e8511d !important;
    color: white !important;
    font-family: "Roboto", sans-serif !important;
    margin-right: 5px !important;
}
.pro-bg {
    background-image: url("/images/site/assets/pro.jpeg");
    background-size: cover;
}
.dynamic-content a {
    @apply text-primary;
}
.dynamic-content h2,
.dynamic-content h3,
.dynamic-content h4,
.dynamic-content h5 {
    @apply !font-sourceSans font-bold;
}
.dynamic-content h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    @apply dark:text-white;
}
.dynamic-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    @apply dark:text-white;
}
.dynamic-content h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    @apply dark:text-white;
}
.dynamic-content h4 {
    font-size: 1.15rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    @apply dark:text-white;
}
.dynamic-content ul li code,
.content-on-quiz ul li code,
.dynamic-content ol li code,
.content-on-quiz ol li code {
    @apply text-primary font-geistMono text-base bg-primary bg-opacity-[0.09] rounded-lg px-1.5 py-1;
}
.dynamic-content p code,
.content-on-quiz p code {
    @apply text-primary font-geistMono text-base bg-primary bg-opacity-[0.09] rounded-lg px-1.5 py-1;
}
.dynamic-content ul,
.content-on-quiz ul {
    @apply list-inside pl-9 list-disc;
}
.dynamic-content ol,
.content-on-quiz ol {
    @apply list-inside pl-9 list-decimal;
}
.dynamic-content blockquote,
.editor blockquote,
.content-on-quiz blockquote,
.content-on-quiz blockquote.warning,
.input blockquote,
.input blockquote.warning {
    @apply border-l-4 border-primary pl-4 py-3 pr-1 mt-5 bg-primary bg-opacity-[0.07] relative rounded-r-lg;
    margin-bottom: 17px !important;
}
.dynamic-content blockquote.warning::before,
.editor blockquote.warning::before,
.content-on-quiz blockquote.warning::before,
.input blockquote.warning::before {
    content: "";
    position: absolute;
    top: -0.5em; /* Ajustez selon le positionnement souhaité */
    left: -1em; /* Ajustez selon le positionnement souhaité */
    width: 35px;
    height: 35px;
    background: url("/images/site/assets/warning.svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.dynamic-content blockquote.info,
.editor blockquote.info,
.content-on-quiz blockquote.info,
.input blockquote.info {
    @apply border-l-4 !border-blue-500 pl-4 py-3 pr-1 mt-5 !bg-blue-500 bg-opacity-[0.07] relative;
}
.dynamic-content blockquote.info::before,
.editor blockquote.info::before,
.content-on-quiz blockquote.info::before,
.input blockquote.info::before {
    content: "";
    position: absolute;
    top: -0.5em; /* Ajustez selon le positionnement souhaité */
    left: -1em; /* Ajustez selon le positionnement souhaité */
    width: 35px;
    height: 35px;
    background: url("/images/site/assets/info.svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.dynamic-content blockquote.question,
.editor blockquote.question,
.content-on-quiz blockquote.question,
.input blockquote.question {
    @apply border-l-4 !border-gray-500 pl-4 py-3 pr-1 mt-5 !bg-gray-500 bg-opacity-[0.07] relative;
}
.dynamic-content blockquote.question::before,
.editor blockquote.question::before,
.content-on-quiz blockquote.question::before,
.input blockquote.question::before {
    content: "";
    position: absolute;
    top: -0.5em; /* Ajustez selon le positionnement souhaité */
    left: -1em; /* Ajustez selon le positionnement souhaité */
    width: 35px;
    height: 35px;
    background: url("/images/site/assets/question.svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.dynamic-content blockquote p:last-child,
.content-on-quiz blockquote p:last-child {
    margin-bottom: 0 !important;
}
.editor ul {
    @apply list-inside pl-9 list-disc mb-4;
}
.editor ol {
    @apply list-inside pl-9 list-decimal mb-4;
}
.dynamic-content li {
    @apply mb-4;
}
.dynamic-content ul li p,
.content-on-quiz ul li p,
.dynamic-content ol li p,
.content-on-quiz ol li p {
    display: inline;
}
.comma ~ .comma::before {
    content: ", ";
}

.bg-quiz {
    background-image: url("/images/site/assets/game.svg");
    background-size: cover;
}
.multiverse,
.multiverse-dark {
    @apply duration-150;
    position: relative;
}
.multiverse::before,
.multiverse::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    @apply border border-gray-200 rounded bg-white duration-150;
    transform: translate(0, 0);
}
.bold {
    font-weight: 700;
}
.multiverse-dark::before,
.multiverse-dark::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    @apply border border-gray-900 rounded bg-bgDark-900 duration-150;
    transform: translate(0, 0);
}
.multiverse::after,
.multiverse-dark::after {
    transform: translate(5px, 5px);
}
.multiverse:hover::before,
.multiverse-dark:hover::before {
    transform: rotate(-4deg);
}
.multiverse:hover::after,
.multiverse-dark:hover::after {
    transform: translate(5px, 5px) rotate(4deg);
}
.personal-drop {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}
.parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
.content-on-quiz p:last-child {
    margin-bottom: 0 !important;
}
.content-on-quiz p {
    margin-bottom: 0.7rem !important;
}
.content-on-quiz p:has(+ ul) {
    margin-bottom: 0 !important;
}
.content-on-message p {
    margin-bottom: 0 !important;
}
.content-on-body,
.content-on-message {
    overflow-wrap: break-word;
}
.content-on-message img,
.content-on-body img,
.content-on-quiz img {
    max-width: 100% !important;
    overflow: hidden;
}
.content-on-message pre,
.dynamic-content pre,
.content-on-quiz pre {
    white-space: break-spaces !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
}
.content-on-body ul,
.content-on-message ul {
    list-style-type: disc;
    padding-left: 40px;
}
.content-on-body ol,
.content-on-message ol {
    list-style-type: decimal;
    padding-left: 40px;
}
.content-on-body ul li,
.content-on-message ul li {
    margin-bottom: 0.5rem;
}
.dynamic-content pre code,
.content-on-body pre code,
.content-on-quiz pre code {
    @apply font-geistMono;
}
.dynamic-content code,
.content-on-quiz code {
    @apply font-geistMono;
}
.dynamic-content p {
    margin-bottom: 1rem !important;
}
.dynamic-content p,
.dynamic-content ul,
.dynamic-content ol,
.dynamic-content blockquote {
    /* max-width: 90%!important;
    margin-left: auto!important;
    margin-right: auto!important; */
}
.dynamic-content table,
.content-on-quiz table {
    width: 100% !important;
    @apply rounded-xl text-base border dark:border-borderDark mb-3;
}
.dynamic-content tbody,
.content-on-quiz tbody {
    border-radius: 0.75rem !important;
}
.dynamic-content table td,
.dynamic-content table th,
.content-on-quiz table td,
.content-on-quiz table th {
    /* border-width: 1px !important; */
    /* border-color: #e8511d !important; */
    /* border-style: solid !important; */
    @apply border dark:border-borderDark;
    padding: 10px !important;
}
.font-sourceCode {
    font-family: var(--source-code-font) !important;
}

/* BELIEVEMY ROW ANIMATION */
/* .row-believemy:hover .item-believemy {
    transform: translateX(-25%);
}
.item-believemy:hover ~ .item-believemy {
    transform: translateX(25%);
}
.item-believemy {
    transition: transform 300ms ease 100ms;
}
.item-believemy:hover {
    transform: scale(1.5) !important;
} */
.embla {
    --slide-spacing: 1rem;
    --slide-size: 33.3%;
    --slide-height: 19rem;
}
.embla__viewport {
    /* overflow: hidden; */
}
.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}
.gradient-text {
    background: linear-gradient(90deg, #e8511d 0%, #e51578 59.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.embla__slide {
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
}
@layer utilities {
    /* @variants dark { */
    .bg-for-newDark {
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #1c1d31 54.73%
        );
    }
    .bg-for-new {
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #f9fafb 54.73%
        );
    }
    .bg-for-articleDark {
        background: linear-gradient(0deg, #1c1d31, transparent 80%);
    }

    .bg-for-article {
        background: linear-gradient(0deg, #fafafa, transparent 80%);
    }

    .text-shadow {
        textshadow: 2px 2px 4px rgba(255, 255, 255, 0.45);
    }

    .text-shadow-dark {
        textshadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }

    .bg-gradient-serie {
        background: linear-gradient(0deg, #f3f4f6, transparent 50%);
    }

    .bg-gradient-serie-dark {
        background: linear-gradient(0deg, #2a2b3e, transparent 50%);
    }
    /* } */
}

/* HEADER */
.bg-gradient-header {
    background: linear-gradient(180deg, #000 -37.28%, rgba(0, 0, 0, 0) 100%);
}

.bg-gradient-controls {
    background: linear-gradient(0deg, #000 -37.28%, rgba(0, 0, 0, 0) 100%);
}

.cursor-none {
    cursor: none;
}

.dynamic-content table thead {
    /* background-color: #e8511d !important; */
    @apply bg-gray-50 dark:bg-bgDark-550 text-black dark:text-lightBlue text-base;
    /* color: white !important; */
}
.dynamic-content table tr:first-child {
    /* background-color: #e8511d !important; */
    @apply bg-gray-50 dark:bg-bgDark-550 text-black dark:text-lightBlue text-base;
    /* color: white !important; */
}
code[class*="language-"],
pre[class*="language-"] {
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
}
.content-on-message pre,
.content-on-body pr,
.content-on-body img,
.content-on-message img {
    border-radius: 5px;
    margin-top: 5px;
}
.content-on-body ul {
    list-style-type: disc;
    padding-left: 40px;
}
.list-wrapper {
    position: relative;
}
.list-item-wrapper {
    margin-top: 10px;
    position: relative;
}
.list-bullet {
    float: left;
    margin-right: 20px;
    background: #f78255;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 100px;
    font-weight: 700;
    color: white;
    text-align: center;
}
.list-item {
    display: table-row;
    vertical-align: middle;
}
.list-title {
    font-weight: 700;
}
.list-text {
    font-weight: 400;
    font-size: 0.9rem;
}
.red-line {
    background: #f78255;
    z-index: -1;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 15px;
}
.white-line {
    z-index: -1;
    top: 0px;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 15px;
}
.large-mat {
    padding: 15rem 0;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
}
.large-mat::before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    background-color: #000;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
/* xl */
@media (max-width: 1400px) {
    .welcome-bg {
        background-size: 65%;
        background-position: 130% top;
    }
}
/* lg */
@media (max-width: 1210px) {
    .welcome-bg {
        background-size: 70%;
        background-position: 130% top;
    }
}
/* md */
@media (max-width: 992px) {
    .welcome-bg {
        background-size: 90%;
        background-position: 190% top;
    }
}
/* sm */
/* @media (max-width: 768px) {
} */
/* xs */
@media (max-width: 576px) {
    .welcome-bg {
        background-size: 90%;
        background-position: 145% top;
    }
}
@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --radius: 0.5rem;
    }
    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}
@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}
